import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { QApiReferenceService } from '../services/q-api-reference.service';
import { QHttpHelperService } from '../services/q-http-helper.service';
import { User } from '../interfaces/user';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginservService {

  private httpParams: HttpParams;

  constructor(private httpC: HttpClient,
    private apiReference: QApiReferenceService, private httpCT: QHttpHelperService) { }

  validateUser(username: string, passWord: string, recaptchaToken: string): Observable<any> {
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('token');

    const params = { usernameOrEmail: username, password: passWord, recaptchaToken: recaptchaToken };

    const req = new HttpRequest('post', environment.baseUrl + '/auth/authenticate', params);
    return this.httpC.request(req).pipe(response => {
      return response;
    });
  }




  getUserDetails(jwToken: string): Observable<any> {
    const req = new HttpRequest('post', environment.baseUrl + '/auth/check-jwt', { jwt: jwToken });
    return this.httpC.request(req).pipe(response => {
      return response;
    });
  }

  setSession(user: User) {
    if (user) {
      sessionStorage.setItem('currentUser', JSON.stringify(user));

    }
  }

  getUserRolesP(roleId): Observable<any> {
    console.log(roleId);
    const params = new HttpParams().set('roleId', roleId);
    const req = this.httpCT.Get(environment.baseUrl +
      this.apiReference.baseURLForRoleP, params);

    return req.pipe(response => {

      return response;
    });

  }

  logout() {
    sessionStorage.clear();
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('RolePrivilege');

  }

  getStore(storeId) {
    if (storeId != null) {

      const params = new HttpParams().set('storeId', storeId);

      const req = this.httpCT.Get(environment.baseUrl + this.apiReference.baseURLForGetStore, params);

      return req.pipe(response => {
        console.log('res' + JSON.stringify(response));
        return response;
      });
    }
  }
  getAssignedStores(empId) {
    if (empId != null) {

      const params = new HttpParams().set('empId', empId);

      const req = this.httpCT.Get(environment.baseUrl + this.apiReference.baseURLForGetAssignedStores, params);

      return req.pipe(response => {
        console.log('res' + JSON.stringify(response));
        return response;
      });
    }
  }
}

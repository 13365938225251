import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { QApiReferenceService } from './q-api-reference.service';
import { QHttpHelperService } from './q-http-helper.service';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SessionsService {
  constructor(public http: HttpClient,
  private _apiReference: QApiReferenceService, private _http: QHttpHelperService) { }

  startSessionFunction(agentId , desknumber , empCode): Observable<any> {
   // const OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   if (agentId != null && desknumber != null) {

    const params = new HttpParams().set('id', agentId)
    .set('desknumber', desknumber).set('empCode', empCode);

    const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForStartSession , params);
   // const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForStartSession , params);

    return req.pipe(response => {
    console.log('res' + JSON.stringify(response));
    return response;
    });
  }
  }




  endSessionFunction(Id ): Observable<any> {
    // const OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    if (Id != null ) {

     const params = new HttpParams().set('id', Id);

     const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForEndSession , params);
    // const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForStartSession , params);

     return req.pipe(response => {
     console.log('res' + JSON.stringify(response));
     return response;
     });
   }
   }

   getCurrentSessionsByStore(storeCode ): Observable<any> {
    // const OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    if (storeCode != null ) {

     const params = new HttpParams().set('storeCode', storeCode);

     const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForCurrentSession , params);
    // const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForStartSession , params);

     return req.pipe(response => {
     console.log('res' + JSON.stringify(response));
     return response;
     });
   }
   }


   
   flushSessionByID(onUserid , sessionId): Observable<any> {

    if (onUserid != null && sessionId != null  ) {

    const params = {empId: onUserid, sessionId};

    const req = this._http.Post(environment.baseUrl + this._apiReference.baseURLForFlushSession , params);

    return req.pipe(response => {
    //  console.log('res' + JSON.stringify(response));
     return response; });
           }
         }


         
         getPendingSessionsByStore(storeCode ): Observable<any> {
          // const OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
          if (storeCode != null ) {
      
           const params = new HttpParams().set('storeCode', storeCode);
      
           const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForPendingSessions , params);
          // const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForStartSession , params);
      
           return req.pipe(response => {
           console.log('res' + JSON.stringify(response));
           return response;
           });
         }
         }

         getPendingSessionsByStoreListId(storeIdList ): Observable<any> {
                // console.log('sessionsId: ' + storeId );

                const params = {storeIds: storeIdList} ;
                // new HttpParams().set('storeCodes', storeIdList);
    
                const req = this._http.Post(environment.baseUrl + this._apiReference.baseURLForPendingSessionsByStoreListIds , params);
    
                return req.pipe(response => {
                //  console.log('res' + JSON.stringify(response));
                 return response; });
         }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LoginservService } from './loginserv.service';
import { OauthDetails } from '../interfaces/oauth-details.model';
import { QHttpHelperService } from '../services/q-http-helper.service';
import { User } from '../interfaces/user';
import { SalesRolesConst } from '../constants/salesRolesConst';
import { Stores } from '../interfaces/stores';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguages } from 'src/app/core/enums/appLanguages';
import { CookieService } from 'ngx-cookie-service';
import { LocalKeys } from '../core/enums/localKeys';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public error = null;
  AppLanguages = AppLanguages;
  selectedLang = 'en';
  isEnglish = false;
  recaptchaToken = '';

  authorizedCredentials: OauthDetails = new OauthDetails();
  private OnUser: User;

  constructor(private router: Router, private ngxService: NgxUiLoaderService,
    private loginService: LoginservService, private httpHelper: QHttpHelperService,
    private salesRoleConst: SalesRolesConst, private translate: TranslateService,
    private cookie: CookieService, private http: HttpClient) { }

  resolved(captchaRes: string) {
    this.recaptchaToken = captchaRes;
  }

  ngOnInit() {
    this.isEnglish = this.translate.currentLang === AppLanguages.English;
    if (this.cookie.get(LocalKeys.Language)) {
      this.selectedLang = this.cookie.get(LocalKeys.Language);
    }
  }

  onLanguageChange(lang: string) {
    this.selectedLang = lang;
    console.log('lannnng:::' + lang);
    if (this.cookie.check(LocalKeys.Language)) {
      this.cookie.delete(LocalKeys.Language);
    }
    this.cookie.set(LocalKeys.Language, lang, 3600 * 24, '/');
    location.reload();
  }

  loginUser(event) {
    this.ngxService.start();
    event.preventDefault();
    const target = event.target;
    const email = target.querySelector('#email').value;
    const password = target.querySelector('#password').value;

    this.loginService.validateUser(email, password, this.recaptchaToken).subscribe(response => {
      if (response.type === 4 && response.body) {
        this.authorizedCredentials.accessToken = response.body.jwt;


        sessionStorage.setItem('token', JSON.stringify(this.authorizedCredentials));
        this.httpHelper.setAuthHeaders(response.body.jwt);

        this.loginService.getUserDetails(response.body.jwt).subscribe(resp => {
          if (resp.type === 4) {
            if (resp.body.id) {
              this.loginService.setSession(resp.body);
              this.OnUser = resp.body;

              this.loginService.getAssignedStores(resp.body.id).subscribe(resp4 => {
                if (resp4.type === 4) {

                  if (resp4.body.length > 0) {
                    let store: Stores[] = [];
                    for (let i = 0; i < resp4.body.length; i++) {
                      store.push(resp4.body[i].storeId);


                    }

                    sessionStorage.setItem('OnBranch', JSON.stringify(store));
                    //////////////////////////// get user role
                    this.loginService.getUserRolesP(resp.body.roleId.id).subscribe(resp3 => {

                      if (resp3.body) {
                        if (resp3.type === 4 && resp3.body.length !== 0) {
                          sessionStorage.setItem('RolePrivilege', JSON.stringify(resp3.body));

                          // then redirect, if he sales agent go to tickets
                          if (resp.body.roleId.id == this.salesRoleConst.SalesAgent.toString()) {
                            console.log('role id1:' + resp.body.roleId.id);
                            this.router.navigateByUrl('/tickets');
                          } else {
                            console.log('role id2:' + resp.body.roleId.id);
                            this.router.navigateByUrl('/dashboard');
                          }


                        } else {
                          console.log('...');
                        }

                      } else {
                        console.log('preflight...');
                      }
                    }, error => {
                      this.ngxService.stop();
                      if (error.error.message.includes('JWT') || error.error.message.includes('token')) {
                        this.router.navigate(['../login']);
                      }

                    });
                  } else {
                    let store: Stores[] = [];
                    sessionStorage.setItem('OnBranch', JSON.stringify(store));

                    this.loginService.getUserRolesP(resp.body.roleId.id).subscribe(resp3 => {

                      if (resp3.body) {
                        if (resp3.type === 4 && resp3.body.length !== 0) {
                          sessionStorage.setItem('RolePrivilege', JSON.stringify(resp3.body));

                          // then redirect, if he sales agent go to tickets
                          if (resp.body.roleId.id == this.salesRoleConst.SalesAgent.toString()) {
                            console.log('role id1:' + resp.body.roleId.id);

                            // this.ngxService.stop();

                            this.router.navigateByUrl('/tickets');
                          } else {
                            console.log('role id2:' + resp.body.roleId.id);

                            // this.ngxService.stop();
                            this.router.navigateByUrl('/dashboard');
                          }


                        } else {
                          console.log('...');
                        }

                      } else {
                        console.log('preflight...');
                      }
                    }, error => {
                      this.ngxService.stop();
                      if (error.error.message.includes('JWT') || error.error.message.includes('token')) {
                        this.router.navigate(['../login']);
                      }

                    });
                  }
                } else {
                  console.log('preflight...');
                }
              }, error => {
                console.log('ERROR...' + error);
                if (error.error.message.includes('JWT') || error.error.message.includes('token')) {
                  this.router.navigate(['../login']);
                }
              });
            }
          } else {
            console.log('...');
          }
        }, error => {
          this.error = 'Error getting user by user name';
          this.ngxService.stop();
          if (error.error.message.includes('JWT') || error.error.message.includes('token')) {
            this.router.navigate(['../login']);
          }
        });
      }
    }, error => {

      this.error = 'invalid userame/password';
      console.log(JSON.stringify(error));
      this.ngxService.stop(); if (error.error.message.includes('JWT') || error.error.message.includes('token')) {
        this.router.navigate(['../login']);
      }

    }
    );
  }

}

import {AfterViewInit, Injectable, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string;       // Possible values: link/dropDown/extLink
  name?: string;      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
  image?: string;
  imagehover?: string;
}

export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string;       // Display text
  state?: string;     // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
  disabled?: boolean;
  image?: string;
  imagehover?: string;
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements OnInit {

  languageChanges = true;

  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false
  };

  constructor() {
  }

  defaultMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      description: 'Dashboard',
      type: 'link',
      image: 'assets/images/menu-icon/normal/dashboard.svg',
      imagehover: 'assets/images/menu-icon/hover/dashboard.svg',
      state: '/dashboard'
    },
    // {
    //   name: 'Profiles',
    //   description: 'profiles',
    //   type: 'link',
    //   image: 'assets/images/menu-icon/normal/users.svg',
    //   imagehover: 'assets/images/menu-icon/hover/users.svg',
    //   state: '/profiles'
    // },
    {
      name: 'Tickets',
      description: 'tickets',
      type: 'link',
      image: 'assets/images/menu-icon/normal/tickets.svg',
      imagehover: 'assets/images/menu-icon/hover/tickets.svg',
      state: '/tickets'
    },
    {
      name: 'Reports',
      type: 'link',
      description: 'reports',
      image: 'assets/images/menu-icon/normal/reports.svg',
      imagehover: 'assets/images/menu-icon/hover/reports.svg',
      state: '/reports',
      sub: [
        {icon: 'fa fa-sellsy', name: 'Overview', state: '/reports/overview', type: 'link'},
        {icon: 'fa fa-product-hunt', name: 'Analytics', state: '/reports/analytics', type: 'link'},
        {icon: 'pi pi-briefcase', name: 'Users', state: '/reports/users', type: 'link'},
        {icon: 'pi pi-briefcase', name: 'Stats', state: '/reports/stats', type: 'link'},
        {icon: 'pi pi-briefcase', name: 'Live (beta)', state: '/reports/live', type: 'link'},
        {icon: 'pi pi-briefcase', name: 'Tickets', state: '/reports/report-tickets', type: 'link'}
      ]
    },
    {
      name: 'Users',
      description: 'users',
      type: 'link',
      image: 'assets/images/menu-icon/normal/users.svg',
      imagehover: 'assets/images/menu-icon/hover/users.svg',
      state: '/users'
    },
    {
      name: 'Branches',
      description: 'branches',
      type: 'link',
      image: 'assets/images/menu-icon/normal/hotel.svg',
      imagehover: 'assets/images/menu-icon/hover/hotel.svg',
      state: '/branches'
    },
    {
      name: 'Services',
      description: 'profiles',
      type: 'link',
      image: 'assets/images/menu-icon/normal/services.svg',
      imagehover: 'assets/images/menu-icon/hover/services.svg',
      state: '/services'
    },
  ];

  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();


  ngOnInit(): void {

  }

}

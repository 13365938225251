import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalesRolesConst {
  constructor() { }

BranchManager = 3;
SalesAgent = 4;
Supervisor = 5;
StoreKeeper = 6;
StoreOperationsOfficer=18
roleBranchList: any[] =
    [{ 'id' : '3', 'roleName': 'BranchManager' }, { 'id': '4', 'roleName': 'SalesAgent' },
     { 'id' : '5', 'roleName': 'Supervisor' }, { 'id': '6', 'roleName': 'StoreKeeper' },
     {'id': '18', 'roleName': 'StoreOperationsOfficer'}];

     AllSalesRolesIdList: any[] = [ 3  , 4, 5 , 6 , 18 ];
      SalesRolesIdList: any[] = [  4, 5 , 6 , 18 ];

}

import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PrivilegeConst } from 'src/app/constants/privilegeConst';
import { Router } from '@angular/router';
import { ModelsServiceService } from '../models-service.service';
import { Model } from 'src/app/interfaces/model';
import { AlertPopService } from 'src/app/alert-popup/alertpop.service';
import * as XLSX from 'xlsx';
import { RolePrivilege } from 'src/app/interfaces/role_privilege';


@Component({
  selector: 'app-all-models',
  templateUrl: './all-models.component.html',
  styleUrls: ['./all-models.component.scss']
})
export class AllModelsComponent implements OnInit {

  modelsList:Model[] = [];
  // public addnewc = false;

  page :number = 1;
  pageSize :number = 25;
  ModelsManagement = false;
  public OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  public OnPrivilege: RolePrivilege[] = JSON.parse(sessionStorage.getItem('RolePrivilege'));
  constructor( private ngxService: NgxUiLoaderService, private alertPopup: AlertPopService , 
    private router: Router, private priviclegeConst: PrivilegeConst , private modelService: ModelsServiceService) { 
    
      if ( this.OnUser == null) {
        this.router.navigate(['../login']);
      }
  
      if ( this.OnUser.roleId.id !== 1) {
  
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
      this.priviclegeConst.ModelsManagement.toString()).is_allow) === false) {
        this.router.navigate(['../../../../access-denied']);
     }       
     }
     else {
      this.ModelsManagement = true;
    }
  

      this.getAllModels();
    }

  ngOnInit() {
    this.getAllModels();
    //this.addnewc = false;

  }



  getAllModels() {
    this.ngxService.start();
    this.modelService.getAllModels().subscribe(data => {
      if (data.body) {
        if (data.type === 4 && data.body.length !== 0) {
         this.modelsList = data.body;
         sessionStorage.setItem('modelsList' , JSON.stringify(data.body));

         this.ngxService.stop();
        } else {
          // console.log('Data are empty');
        }
      } else {
        console.log('preflighted response');
      }
        }, error => {
          this.alertPopup.showMsg('please contact your admin: ' + error.error.errorID, 'Error');
          this.ngxService.stop();

          if(error.error.message.includes('JWT') || error.error.message.includes('token') )
          {
            this.router.navigate(['../login']);
          }
 
      } );


  }



  showModel(modelId)
  {
    this.ngxService.start();
    this.modelService.showModel(modelId).subscribe(data => {
      if (data.body) {
        if (data.type === 4 ) {
         this.ngOnInit(); 
       //  this.ngxService.stop();
         
        } else {
          // console.log('Data are empty');
        }
      } else {
        console.log('preflighted response');
      }
        }, error => {
          this.alertPopup.showMsg('please contact your admin: ' + error.error.errorID, 'Error');
          this.ngxService.stop();
          if(error.error.message.includes('JWT') || error.error.message.includes('token') )
          {
            this.router.navigate(['../login']);
          }
 

      } );





    }


    launchModel(modelId)
    {
      this.ngxService.start();
      this.modelService.launchModel(modelId).subscribe(data => {
        if (data.body) {
          if (data.type === 4 ) {
            this.ngOnInit();
          // this.ngxService.stop();
          } else {
            // console.log('Data are empty');
          }
        } else {
          console.log('preflighted response');
        }
          }, error => {
            this.alertPopup.showMsg('please contact your admin: ' + error.error.errorID, 'Error');
            this.ngxService.stop();
            if(error.error.message.includes('JWT') || error.error.message.includes('token') )
            {
              this.router.navigate(['../login']);
            }
   
        } );
  
  
    }

  Search(modelName, show, launch)
  {
    event.preventDefault();
    this.modelsList= JSON.parse(sessionStorage.getItem('modelsList'));
    if(modelName != null){
      this.modelsList = this.modelsList.filter(x=>x.modelName.includes(modelName) );
    } 
    if(show != 2 ){
      this.modelsList = this.modelsList.filter(x=>x.showFlag == show);
    }
    if(launch != 2 ){
      this.modelsList = this.modelsList.filter(x=>x.launchFlag == launch);
    }

  }


  ngOnDestroy(){
    sessionStorage.removeItem('modelsList');
  }


  ExportExcel(): void {

    const fileName = 'models' + '.xlsx';
   
    let ws: XLSX.WorkSheet
       ws = XLSX.utils.json_to_sheet(this.modelsList);
       ws['!cols'] = [];
       ws['!cols'][0] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    /* save to file */
    XLSX.writeFile(wb, fileName);
  
  }



  // addnew() {
  //   this.addnewc = !this.addnewc;
  //   }

  // addnewcolor(event) {
  //   event.preventDefault();
  //   this.ngxService.start();
  //   const target = event.target;
  //   const item_color = target.querySelector('#itemColor').value.toString().trim();
  //   const item_hex_color = target.querySelector('#itemHexColor').value.toString().trim();
  //   this.modelService.addNewColor(item_color, item_hex_color).subscribe(data => {
  //    alert('data.body' + data.body)
  //     if (data.body) {
  //       if(data.body.status === 'SUCCESS'){

  //   this.ngxService.stop();
  // }
  //       } else {
  //         console.log('preflighted response');
  //       }
  //         }, error => {
  //           console.log(error);
    
  //           this.ngxService.stop();
  //           this.alertPopup.showMsg( 'There is an error adding a new color!' , 'Add Color', true);
  //           if(error.error.message.includes('JWT') || error.error.message.includes('token') )
  //           {
  //             this.router.navigate(['../login']);
  //           }
   
  //       } );


  // }


}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { LoginservService } from 'src/app/login/loginserv.service';
import { User } from 'src/app/interfaces/user';
import { Stores } from 'src/app/interfaces/stores';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguages } from 'src/app/core/enums/appLanguages';
import { CookieService } from 'ngx-cookie-service';
import { LocalKeys } from 'src/app/core/enums/localKeys';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  public OnBranch: Stores = JSON.parse(sessionStorage.getItem('OnBranch'));
  AppLanguages = AppLanguages;
  selectedLang = 'en';
  isEnglish = false;

  constructor(private router: Router , private loginServ: LoginservService , private translate: TranslateService,
              private cookie: CookieService) {
  }

  ngOnInit() {
    this.isEnglish = this.translate.currentLang === AppLanguages.English;
  if (this.cookie.get(LocalKeys.Language)) {
      this.selectedLang = this.cookie.get(LocalKeys.Language);
    }
  }

  logoutAction() {

    this.loginServ.logout();
    this.router.navigate(['/login']);
  }
  onLanguageChange(lang: string) {
    console.log('lannnngggg' +lang);
    this.selectedLang = lang;
    if (this.cookie.check(LocalKeys.Language)) {
      this.cookie.delete(LocalKeys.Language);
    }
    this.cookie.set(LocalKeys.Language, lang, 3600 * 24, '/');
        location.reload();
  }
}

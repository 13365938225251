import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QHttpHelperService } from 'src/app/services/q-http-helper.service';
import { QApiReferenceService } from 'src/app/services/q-api-reference.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelsServiceService {

  constructor(public httpC: HttpClient, private apiReference: QApiReferenceService,
    private http: QHttpHelperService) { }

  getAllModels(): Observable<any> {

    // const req =  this._http.Get(environment.baseUrl + this._apiReference.baseURLForGetAllPrivilege);
    const req =  this.http.Get(environment.baseUrl + this.apiReference.baseURLForGetAllModels);
    return req.pipe(response => {
      return response;
    });

  }

  getAllColors(): Observable<any> {

    // const req =  this._http.Get(environment.baseUrl + this._apiReference.baseURLForGetAllPrivilege);
    const req =  this.http.Get(environment.baseUrl + this.apiReference.baseURLForGetAllColors);
    return req.pipe(response => {
      return response;
    });

  }

  showModel(modelId): Observable<any> {

    // const req =  this._http.Get(environment.baseUrl + this._apiReference.baseURLForGetAllPrivilege);
    // const params = {modelName: modelName};

    const req =  this.http.Post(environment.baseUrl + this.apiReference.baseURLForShowModel+'?modelId='+ modelId);
    return req.pipe(response => {
      return response;
    });

  }

  launchModel(modelId): Observable<any> {

    // const req =  this._http.Get(environment.baseUrl + this._apiReference.baseURLForGetAllPrivilege);
    // const params = {modelName: modelName};

    const req =  this.http.Post(environment.baseUrl + this.apiReference.baseURLForLaunchModel+'?modelId='+ modelId);
    return req.pipe(response => {
      return response;
    });

  }


  addNewColor(item_color ,item_hex_color): Observable<any> {

    // const req =  this._http.Get(environment.baseUrl + this._apiReference.baseURLForGetAllPrivilege);
    if (item_color != null ||item_hex_color != null ) {

    const params = {item_color: item_color , item_hex_color: item_hex_color};
    const req =  this.http.Post(environment.baseUrl + this.apiReference.baseURLForAddNewColors , params);
    return req.pipe(response => {
      return response;
    });
  }
  }
  

  getItemHexColors(): Observable<any> {

    // const req =  this._http.Get(environment.baseUrl + this._apiReference.baseURLForGetAllPrivilege);
    const req =  this.http.Get(environment.baseUrl + this.apiReference.baseURLForGetItemHexColors );
    return req.pipe(response => {
      return response;
    });
  }

}
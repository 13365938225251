import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './views/layout/header/header.component';
import {SidebarComponent} from './views/layout/sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { SessionsService } from './services/sessions.service';
import { AlertPopupComponent } from '../app/alert-popup/alert-popup.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedBasicModule } from './sharedBasic.module';
import { TranslateModule } from '@ngx-translate/core';
import { translationOptions } from './core/config/translation';
import { AllModelsComponent } from './views/models-managements/all-models/all-models.component';
import { ColorsComponent } from './views/models-managements/colors/colors.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  logoPosition: 'center-center',
  logoSize: 300,
  logoUrl: '../assets/images/STCC_w.svg',
  pbColor: '#fff',
  pbDirection: 'ltr',
  pbThickness: 3
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    LoginComponent,
    AlertPopupComponent,
    AccessDeniedComponent,
    AllModelsComponent,
    ColorsComponent
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgbModule,
    TranslateModule.forRoot(translationOptions),
    SharedBasicModule 
  ],
  providers: [SessionsService],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

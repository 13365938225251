import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SidebarComponent} from './views/layout/sidebar/sidebar.component';
import {LoginComponent} from './login/login.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'queueTicket',
   // component: TicketLoginComponent
    loadChildren: './views/queue-ticket/queue-ticket.module#QueueTicketModule'
  },

  {
    path: '',
    component: LoginComponent
  },
  {
    path: '',
    component: SidebarComponent,
    canActivate: [],
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'services',
        loadChildren: './views/service/service.module#ServiceModule'
      },
      {
        path: 'profiles',
        loadChildren: './views/profile/profile.module#ProfileModule'
      },
      {
        path: 'tickets',
        loadChildren: './views/ticket/ticket.module#TicketModule'
      },
      {
        path: 'desks',
        loadChildren: './views/desks/desks.module#DesksModule'
        // component: DesksComponent
      },
      {
        path: 'devices',
        loadChildren: './views/devices/devices.module#DevicesModule'
        // component: DesksComponent
      },
      {
        path: 'reports',
        loadChildren: './views/report/report.module#ReportModule'
      },
      {
        path: 'branches',
        loadChildren: './views/branch/branch.module#BranchModule'
      },
      {
        path: 'users',
        loadChildren: './views/user/user.module#UserModule'
      },
      {
        path: 'role',
        loadChildren: './views/roles/role.module#RoleModule'
      },
      {
        path: 'privilege',
        loadChildren: './views/privileges/privilege.module#PrivilegeModule'
      },
      {
        path: 'pending',
        loadChildren: './views/pending-tickets/pending-tickets.module#PendingTicketsModule'
      }
      ,
      {
        path: 'models-managements',
        loadChildren: './views/models-managements/models-managements.module#ModelsManagementsModule'
      }
      
    ]
  },

  {
    path: '**',
    redirectTo: 'others/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes)
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: false})
  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }

import {Component, OnInit} from '@angular/core';
import {IMenuItem, NavigationService} from '../../../shared/services/navigation.service';
import * as _ from 'lodash';
import { User } from 'src/app/interfaces/user';
import { RolePrivilege } from 'src/app/interfaces/role_privilege';
import { PrivilegeConst } from 'src/app/constants/privilegeConst';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguages } from 'src/app/core/enums/appLanguages';
import { CookieService } from 'ngx-cookie-service';
import { LocalKeys } from 'src/app/core/enums/localKeys';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  nav: IMenuItem[];
  selectedItem: IMenuItem;
  activeMenu: any = new Object();
  public OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  public OnPrivilege: RolePrivilege[] = JSON.parse(sessionStorage.getItem('RolePrivilege'));
  TicketOperation = false;
  WelcomeDashboard = false;
  Reports = false;
  DeskPage = false;
  BranchListPage = false;
  UserPage = false;
  RolesPage = false;
  RolePrivilegePage = false;
ServicesPage = false;
PendingPage = false;
DevicesPage = false;
ModelsManagement = false;
 editService = false;
 isEnglish = false;
 selectedLang='';
  constructor(private navService: NavigationService , private router: Router ,
              private priviclegeConst: PrivilegeConst, private translate: TranslateService ,  private cookie: CookieService  ) {

    if ( this.OnUser == null) {
      this.router.navigate(['../login']);
    }

    if ( this.OnUser.roleId.id !== 1) {
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
                     this.priviclegeConst.TicketOperation.toString()).is_allow) === true) {
                   this.TicketOperation = true;
                                }

      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
                          this.priviclegeConst.WelcomeDashboard.toString()).is_allow) === true) {
                        this.WelcomeDashboard = true;

                             }
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
                               this.priviclegeConst.DeskPage.toString()).is_allow) === true) {
                             this.DeskPage = true;
                                    }
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
                                    this.priviclegeConst.Reports.toString()).is_allow) === true) {
                                  this.Reports = true;
                                         }
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
                                         this.priviclegeConst.BranchListPage.toString()).is_allow) === true) {
                                       this.BranchListPage = true;
     
                                      }
     
   if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
       this.priviclegeConst.PendingPage.toString()).is_allow) === true) {
         this.PendingPage = true;
        }
  
                                      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
        this.priviclegeConst.UserPage.toString()).is_allow) === true) {
       this.UserPage = true;
                                                   }
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
       this.priviclegeConst.RolesPage.toString()).is_allow) === true) {
       this.RolesPage = true;
      }
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
      this.priviclegeConst.RolePrivilegePage.toString()).is_allow) === true) {
       this.RolePrivilegePage = true;
      }  
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
      this.priviclegeConst.ServicesPage.toString()).is_allow) === true) {
       this.ServicesPage = true;
      } 

      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
      this.priviclegeConst.DevicesPage.toString()).is_allow) === true) {
       this.DevicesPage = true;
      }
      

      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
      this.priviclegeConst.ModelsManagement.toString()).is_allow) === true) {
       this.ModelsManagement = true;
      }
      
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
      this.priviclegeConst.editService.toString()).is_allow) === true) {
       this.editService = true;
      }
      
    } else {
          this.TicketOperation = true;
          this.WelcomeDashboard = true;
          this.Reports = true;
          this.DeskPage = true;
          this.BranchListPage = true;
          this.UserPage = true;
          this.RolesPage = true;
          this.RolePrivilegePage = true;
          this.ServicesPage = true;
          this.PendingPage = true;
          this.DevicesPage = true;
          this.ModelsManagement = true;
          this.editService = true;
         }


  }

  ngOnInit() {
    this.isEnglish = this.translate.currentLang === AppLanguages.English;
    if (this.cookie.get(LocalKeys.Language)) {
      this.selectedLang = this.cookie.get(LocalKeys.Language);
    }
    
    this.navService.menuItems$
      .subscribe((items: IMenuItem[]) => {
        this.nav = [];
        items.map(data => {
          if (!data.disabled) {
            this.nav.push(data);
          }
        });
        // this.setActiveFlag();
      });
  }


  onClickChangeActiveFlag(item) {
    this.setActiveMainItem(item);
  }


  setActiveMainItem(item) {
    this.nav.forEach(ite => {
      ite.active = false;
    });
    item.active = true;
  }

  closeChildNav() {
    this.navService.sidebarState.childnavOpen = false;
    this.setActiveFlag();
  }

  checkChildNav(item) {
    this.activeMenu = new Object();
    this.activeMenu[item.name] = true;
    if (_.size(item.sub) === 0 && !_.isUndefined(item.sub)) {
      this.navService.sidebarState.childnavOpen = false;
    }
  }

  getImage(item) {
    if (this.activeMenu[item.name]) {
      if (item.imagehover) {
        return item.imagehover;
      }
    } else {
      if (item.image) {
        return item.image;
      }
    }
  }

  selectItem(item) {
    this.navService.sidebarState.childnavOpen = true;
    this.selectedItem = item;
    this.setActiveMainItem(item);
  }


  setActiveFlag() {
    // if (window && window.location) {
    //   const activeRoute = window.location.hash || window.location.pathname;
    //   this.nav.forEach(item => {
    //     item.active = false;
    //     if (activeRoute.indexOf(item.state) !== -1) {
    //       this.selectedItem = item;
    //       item.active = true;
    //     }
    //     if (item.sub) {
    //       item.sub.forEach(subItem => {
    //         subItem.active = false;
    //         if (activeRoute.indexOf(subItem.state) !== -1) {
    //           this.selectedItem = item;
    //           item.active = true;
    //         }
    //         if (subItem.sub) {
    //           subItem.sub.forEach(subChildItem => {
    //             if (activeRoute.indexOf(subChildItem.state) !== -1) {
    //               this.selectedItem = item;
    //               item.active = true;
    //               subItem.active = true;
    //             }
    //           });
    //         }
    //       });
    //     }
    //   });
    // }
  }


  mouseOut() {
    this.activeMenu = new Object();
    this.navService.sidebarState.childnavOpen = false;
  }

  mouseUp(item) {
    this.activeMenu = new Object();
    this.activeMenu[item.name] = true;
  }
}

import { Injectable } from '@angular/core';
// import { PUT, GET, POST, DELETE, LIST_SCREEN, GROUP_SCREEN } from './stc-global';
import { Observable } from 'rxjs';

import {
  HttpClient, HttpRequest, HttpParams, HttpHeaders,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QHttpHelperService {


  header: HttpHeaders;
  headerTicket: HttpHeaders;
  headerDevice: HttpHeaders;
  headerWFM: HttpHeaders;
  httpParams: HttpParams;
  httpParamss: HttpParams;
  constructor(private httpC: HttpClient) {
  }

  // setQueryParams() {
  //   this.httpParams = new HttpParams().set('client_id', 'test').set('client_secret', 'test').set('grant_type', 'idp_grant');
  // }

  setAuthHeaders(authHeader: string) {
    this.header = new HttpHeaders();
    this.header = this.header.set('Content-Type', 'application/json; charset=utf-8');
    this.header = this.header.set('Accept', 'application/json');
    this.header = this.header.set('Authorization', `Bearer ${authHeader}`);
    //this.header = this.header.set('Authorization', `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtd0B1c2VyIiwiaWF0IjoxNTk1MzM1NDYwLCJleHAiOjE1OTUzNjQyNjB9.CaLUCokfPYzLbcEux46Sa-ndZ495nM8vF5c9RLe3WbuKUCcBt_bDXYNiGfSmT3sivZ3O9gVONGRy3YpZMQQPgw`);
  }

  setAuthHeadersTicket(authHeaderTicket: string) {

    this.headerTicket = new HttpHeaders();
    this.headerTicket = this.headerTicket.set('Content-Type', 'application/json; charset=utf-8');
    this.headerTicket = this.headerTicket.set('Accept', 'application/json');
    this.headerTicket = this.headerTicket.set('Authorization', `Bearer ${authHeaderTicket}`);
  }
  setDeviceHeaders(deviceId: string, deviceSecret: string) {
    this.headerDevice = new HttpHeaders();
    this.headerDevice = this.headerDevice.set('Content-Type', 'application/json; charset=utf-8');
    this.headerDevice = this.headerDevice.set('Accept', 'application/json');
    this.headerDevice = this.headerDevice.set('DeviceAuth', deviceId +':'+ deviceSecret);
  }

  LoginAuthentication(url: string, data: any): Observable<any> {
    this.httpParams = new HttpParams().set('client_id', 'portal').set('client_secret', 'portal').set('grant_type', 'password')
      .set('role', 'ADMIN').set('username', data.userName).set('password', data.passWord);
    const req = new HttpRequest('post', url, this.httpParams);
    return this.httpC.request(req);
  }

  getLoggedInUserData(url, token: string): Observable<any> {
    this.httpParamss = new HttpParams().set('token', token);
    const req = new HttpRequest('get', url, { params: this.httpParamss });
    return this.httpC.request(req);
  }

  Put(url: string, data?: any): Observable<any> {
      // this.addHeaders();
      const req = new HttpRequest('put', url, data, { headers: this.header });
      return this.httpC.request(req);
  }




  GetForAudit(url: string, data: any) {
    const req = new HttpRequest('get', url, data, { headers: this.header, params: data });
    return this.httpC.request(req);
  }

  Get(url: string, args?: HttpParams): Observable<any> {
     // this.addHeaders();
   //  this.setAuthHeaders('6bb0d688-b9cc-44db-9d98-125df4c1f7de');
    const req = new HttpRequest('get', url, { headers: this.header, params: args });
    return this.httpC.request(req);
  }
  

  Patch(url: string, args?: HttpParams): Observable<any> {
   
   const req = new HttpRequest('patch', url, { headers: this.header, params: args });
   return this.httpC.request(req);
 }
 
  deviceGet(url: string, deviceId: string, deviceSecret: string, args?: HttpParams,
     ): Observable<any> {
    // this.addHeaders();
   this.setDeviceHeaders(deviceId, deviceSecret);
   const req = new HttpRequest('get', url, { headers: this.headerDevice, params: args });
   return this.httpC.request(req);
 }

  GetForRole(url: string, code: string): Observable<any> {
    this.addHeaders(code);
    const req = new HttpRequest('get', url, { headers: this.header });
    return this.httpC.request(req);
  }

  Post(url: string, data?: any): Observable<any> {
     // this.addHeaders();
    //  console.log('this.header ' + this.header );
    //  console.log('data ' + data );
     const req = new HttpRequest('post', url, data, { headers: this.header });
     return this.httpC.request(req);
  }

  WFMGet(url: string, args?: HttpParams): Observable<any> {
    this.headerWFM = new HttpHeaders();
    this.headerWFM = this.headerWFM.set('Content-Type', 'application/json; charset=utf-8');
    this.headerWFM = this.headerWFM.set('Accept', 'application/json');

    const req = new HttpRequest('get', url, {  params: args });
    return this.httpC.request(req);
 }

  Post2(url: string, data?: any, header2?: any): Observable<any> {
    // this.addHeaders();
    // console.log('header2 ' + header2 );
    console.log('data ' + data );
    const req = new HttpRequest('post', url, data, { headers: header2 });
    return this.httpC.request(req);
 }

 PostTicket(url: string, data?: any): Observable<any> {

  const req = new HttpRequest('post', url, data, { headers: this.headerTicket });
  console.log('reg:: ' + JSON.stringify(req));
  console.log('this.headerTicket:: ' + this.headerTicket);
  return this.httpC.request(req);
}


  Delete(url: string): Observable<any> {
    const req = new HttpRequest('delete', url, { headers: this.header });
    return this.httpC.request(req);
  }

  addHeaders(role?: string) {
    this.header = this.header.set('accepted-language', 'en');
    this.header = this.header.set('source', 'Qwik Plus');
    if (role) {
      this.header = this.header.set('code', role);
    }
  }

  // addHeadersForGroup() {
  //   this.header = this.header.set('limit', '10');
  //   this.header = this.header.set('page', '1');
  // }
}

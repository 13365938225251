import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PrivilegeConst } from 'src/app/constants/privilegeConst';
import { Router } from '@angular/router';
import { ModelsServiceService } from '../models-service.service';
import { Model } from 'src/app/interfaces/model';
import { AlertPopService } from 'src/app/alert-popup/alertpop.service';
import * as XLSX from 'xlsx';
import { RolePrivilege } from 'src/app/interfaces/role_privilege';
import { Color } from 'src/app/interfaces/color';


@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent implements OnInit {
  public addnewc = false;

  page :number = 1;
  pageSize :number = 20;
  ModelsManagement = false;
  public colorsList:Color[] = [];

  public OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  public OnPrivilege: RolePrivilege[] = JSON.parse(sessionStorage.getItem('RolePrivilege'));
  constructor( private ngxService: NgxUiLoaderService, private alertPopup: AlertPopService , 
    private router: Router, private priviclegeConst: PrivilegeConst , private modelService: ModelsServiceService) { 
      if ( this.OnUser == null) {
        this.router.navigate(['../login']);
      }
  
      if ( this.OnUser.roleId.id !== 1) {
  
      if ((this.OnPrivilege.find(x => x.privilege_id.toString() ===
      this.priviclegeConst.ModelsManagement.toString()).is_allow) === false) {
        this.router.navigate(['../../../../access-denied']);
     }       
     }
     else {
      this.ModelsManagement = true;
    }
    this.getAllColors();
    }

  ngOnInit(): void {
    this.getAllColors();
    this.addnewc = false;

  }


  getAllColors() {
    this.ngxService.start();
    this.modelService.getAllColors().subscribe(data => {
      if (data.body) {
        if (data.type === 4 && data.body.length !== 0) {
         this.colorsList = data.body;
         sessionStorage.setItem('colorsList' , JSON.stringify(data.body));

         const itemColorList: Color[] = [];
          for (let i = 0; i < data.body.length; i++) {
            const c: Color = new Color();
           
            c.id = data.body[i].id;
            c.itemColor = data.body[i].item_color;
            c.itemHexColor = data.body[i].item_hex_color;
          
            itemColorList.push(c);
          } 
          this.colorsList = itemColorList;

         this.ngxService.stop();
        } else {
          this.colorsList = [];
          // console.log('Data are empty');
        }
      } else {
        console.log('preflighted response');
      }
        }, error => {
          this.alertPopup.showMsg('please contact your admin: ' + error.error.errorID, 'Error');
          this.ngxService.stop();

          if(error.error.message.includes('JWT') || error.error.message.includes('token') )
          {
            this.router.navigate(['../login']);
          }
 
      } );


  }
  ngOnDestroy(){
    sessionStorage.removeItem('colorsList');
  }


  addnew() {
    this.addnewc = !this.addnewc;
    }

  addnewcolor(event) {
    event.preventDefault();
    this.ngxService.start();
    const target = event.target;
    const item_color = target.querySelector('#itemColor').value.toString().trim();
    const item_hex_color = target.querySelector('#itemHexColor').value.toString().trim();
   
   if(item_color!= null){
    this.modelService.addNewColor(item_color, item_hex_color).subscribe(data => {
      if (data.body) {
        if(data.body.status === 'SUCCESS'){

    this.ngxService.stop();
    this.alertPopup.showMsg( 'There color added successfully!' , 'Add Color', true);

  }else{
    this.ngxService.stop();
    this.alertPopup.showMsg( 'There is an error adding a new color!' , 'Add Color', true);

  }
        } else {
          console.log('preflighted response');
        }
          }, error => {
            console.log(error);
    
            this.ngxService.stop();
            // this.alertPopup.showMsg( 'There is an error adding a new color!' , 'Add Color', true);
            if(error.error.message.includes('JWT') || error.error.message.includes('token') )
            {
              this.router.navigate(['../login']);
            }
   
        } );
      }else{
         this.alertPopup.showMsg( 'There is an error adding a new color!' , 'Add Color', true);

      }


  }



  Search(itemColor, itemHexColor)
  {
    event.preventDefault();
    this.colorsList= JSON.parse(sessionStorage.getItem('colorsList'));
    if(itemColor != null){
      this.colorsList = this.colorsList.filter(x=>x.itemColor == (itemColor) );
    } 
    if(itemHexColor != null){
      this.colorsList = this.colorsList.filter(x=>x.itemHexColor == (itemHexColor) );
    } 

  }

  getItemHexColors(){

    this.modelService.getItemHexColors().subscribe(data => {
      if (data.body) {
    
      if (data.type === 4 && data.body.status === 'SUCCESS') {
    
    
    //  this.alertPopup.showMsg( 'There store added successfully!' , 'Add Store', true);
    //  this.ngOnInit();
       } else {
       // this.ngxService.stop();
        //this.alertPopup.showMsg( data.body.message , data.body.status, true);
       }
    
      } else {
         console.log('preflighted response');
       }
         }, error => {
           console.log(error);
          //  this.ngxService.stop();
           if(error.error.message.includes('JWT') || error.error.message.includes('token') )
           {
             this.router.navigate(['../login']);
           }
    
       } );
        
    
  }


}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QApiReferenceService {

  constructor() { }

  // Session
  baseURLForStartSession = '/queue-management/start_session';
  baseURLForEndSession = '/queue-management/end_session';
  baseURLForCurrentSession = '/queue-management/current-store-sessions';
  baseURLForFlushSession ='/queue-management/flush-session';
  baseURLForPendingSessions='/queue-management/pending-sessions';
  baseURLForPendingSessionsByStoreListIds='/queue-management/get_pending_sessions_by_store_id_list';

  // Ticket
  baseURLForInitiateTicket = '/queue-management/initiate-ticket';
  baseURLForCallTicket = '/queue-management/call-ticket';
  baseURLForEndOrNoShow = '/queue-management/end-or-no-show';
  baseURLForRecall = '/queue-management/recall-ticket';
  baseURLForTicketDashboard = '/queue-management/ticket-dashboard';
  baseURLForGetPendingTickets = '/queue-management/pending-tickets';
  baseURLForGetCurrentTickets = '/queue-management/get-current-tickets';
  baseURLForFlushTicket = '/queue-management/flush-ticket';
  baseURLForPendingTicketsByStoreListIds = '/queue-management/get_pending_tickets_by_store_id_list';
  baseURLForflushCurrentTicket= '/queue-management/flush-current-ticket';
  baseURLForGetLaunchPendingItems='/queue-management/get-launch-pending-items'
  baseURLForReScheduleTicket='/queue-management/reschedule-ticket';

  // Stores
  baseURLForGetStoreByEmp = '/queue-management/get_store_by_emp';
  baseURLForUpdateStore = '/queue-management/update_store';
  baseURLForGetStore = '/queue-management/get_store';
  baseURLForDeleteStore = '/queue-management/delete_store';
  baseURLForSearchStore = '/queue-management/search_store';
  baseURLForAddStore = '/queue-management/add_store';
  baseURLForAddNonRetailStore = '/queue-management/add_non_channels_store';
  baseURLForgetStoresByStoreH = '/queue-management/get_Stores_By_StoreH';
  uRLForgetQMSStoresByCity ='/queue-management/get_store_by_cityId';
  baseURLForAssignStores ='/queue-management/assign_stores';
  baseURLForGetAssignedStores ='/queue-management/get_assigned_stores';
  baseURLForGetAllStores = '/queue-management/get_all_stores';
  baseURLForGetUsersByStoreCode = '/queue-management/get-users-by-store-code';
  baseURLForEditUseractivationByStoreCode = '/queue-management/edit-user-activation-by-store-codes';
  baseURLForUpdateStoreEmployees ='/queue-management/update_store_employees';
  baseURLForDeleteAssignedStore ='/queue-management/delete_assigned_stores';
  baseURLForJob ='/queue-management/run-jobs';
  baseURLForUpdateFromWFM ='/queue-management/update-retail-stores-from-wfm';

  

  // desks
  baseURLForGetAllDesks = '/queue-management/get_desk_by_store_code';
  baseURLForGetAvailableAllDesks = '/queue-management/get_available_desk_by_store_code';
  baseURLForAddDesk = '/queue-management/add_desk';
  baseURLForEditDesk = '/queue-management/update_desk';
  baseURLForDeleteDesk = '/queue-management/delete_desk';
  baseURLForActiveDesk = '/queue-management/active_desk';
  baseURLForSearchDesk = '/queue-management/search_desk';
  baseURLForGetDesksByStoreCodeList = '/queue-management/get_desks_by_store_code_list';
  baseURLForGetDeskAndSessionsByStoreCode='/queue-management/get_desk_and_sessions_by_store_code';

  // User
  baseURLForUpdateUsersRole = '/user-management/user/update-userRole-active';
  baseURLForCreateOpUser = '/user-management/user/create-ops-user';
  baseURLForCreateBranchUser = '/user-management/user/create-agent-user';
  baseURLForCreateNonChannelsUser = '/user-management/user/create-non-channels-user';

  // reports
  baseURLForGetAllBranchAgent = '/queue-management/all-branch-agent';
  baseURLForGetallBranchReport = '/queue-management/all-branch-report';
  baseURLForGetallBranchEBUReport='/queue-management/all-branch-ebu-report';
  baseURLForGetBranchReport = '/queue-management/branch-report';
  baseURLForGetTopAgent = '/queue-management/top-agent';
  baseURLForBranchDetails = '/queue-management/branch-details'
  baseURLForGetLongWaitingTickets = '/queue-management/get-long-waiting-tickets'
  baseURLForGetAllBranchsAgent ='/queue-management/all-branch-agents-report';
  baseURLForGetTopAgentPerAgent = '/queue-management/top-branchs-agent';
  baseURLForGetAllServicesDetails= '/queue-management/all-services-details';
  baseURLForGetTicketsPerHourByService= '/queue-management/tickets-per-hour-by-service';
  baseURLForGetAgentsServices= '/queue-management/agents-services-details';

  
  // Service
  baseURLForStoreServices = '/queue-management/get_store_services';
  baseURLForGetAllStoresServices = '/queue-management/get_all_stores_services';
  baseURLForActiveService = '/queue-management/active_service';
  baseURLForEnableDisableStoreService = '/queue-management/enable_disable_store_service';

  baseURLForSearchService = '/queue-management/search_Service';
  baseURLForAddService = '/queue-management/add_service';
  baseURLForEditService = '/queue-management/edit_service';
  
  baseURLForGetService = '/queue-management/get_service';
  baseURLForGetServiceNames = '/queue-management/get_service_names';
  baseURLForGetDeviceServices = '/queue-management/get_device_services';
  baseURLForActivateDeviceServices = '/queue-management/activate_device_service';
  baseURLForGetStoreServicesAndUserService = '/queue-management/get_store_services_and_user_service';
  baseURLForAssignServiceToUser = '/queue-management/assign_service_to_user';

  
  //Device Reservations
  baseURLForGetAllModels='/qms-device-reservation/get-all-models';
  baseURLForShowModel='/qms-device-reservation/show-model';
  baseURLForLaunchModel='/qms-device-reservation/launch-model';
  baseURLForAddNewColors='/qms-device-reservation/add-new-colors';
  baseURLForGetAllColors='/qms-device-reservation/get-all-colors';
  baseURLForGetItemHexColors='/qms-device-reservation/get-item_hex-colors';
  baseURLForUnReserveItemByUser='/qms-device-reservation/cancel-item-by-user';
  //Devices
  baseURLForGetDevicesByStoreCodeList = '/queue-management/get_devices_by_store_code_list'
  baseURLForValidateDevice = '/queue-management/validate_device'
  baseURLForDeleteDevice = '/queue-management/delete_device'
  
  // settings
  baseURLForActiveUsers = '/user-management/user/get-active-users';
  
  // baseURLForUpdateEmp = '/admin/user/update-users';
  baseURLForUpdateBranchEmp = '/user-management/user/update-users';
  baseURLForChangePW = '/user-management/user/password/change-password';
  
  // baseURLForGetAllPrivilege = '/admin/privilege/get-all-privileges';
  baseURLForUpdateRolePrivilege = '/user-management/user/update-role-privilege';
  baseURLForGetUserByUserId = '/user-management/user/get-user-by-id';
  baseURLForUpdateUserRole = '/user-management/user/update-user-role';
  baseURLForUpdateUserActivation = '/user-management/user/update-user-activation';
  
  // Roles
  baseURLForGetAllRoles = '/user-management/get-all-roles';
  baseURLForCreateRoles = '/user-management/add_role';
  baseURLForEditRoles = '/user-management/edit-role';
  
  // privileges
  baseURLForGetAllPrivilege = '/user-management/privilege/get-all-privileges';
  baseURLForGetRolePrivilege = '/user-management/user/get-role-privilege';
  baseURLForAddRolePrivilege = '/user-management/add-role-privilege';

  // login
  baseURLForBranch = '/pos-management/get-store-info';
  baseURLForRoleP = '/user-management/user/get-role-privilege';

  baseUrl = '/api/'
  loginUser = this.baseUrl + 'auth/oauth/token';
  getUserDetails = this.baseUrl + 'auth/oauth/check_token';
  deletePrvilegeCenter = this.baseUrl + 'admin/privilege/delete/';
  getUserController = this.baseUrl + 'adminuser/';
  getAdminGroupAll = this.baseUrl + 'admin/group';
  updatePrivilegeCenter = this.baseUrl + 'admin/privilege/update';
  insertPrivilegeCenter = this.baseUrl + 'admin/privilege/create';

  //WFM APIs
  WFMURL = '/api/wfms/';
  uRLForgetDistByRegion = this.WFMURL + 'getDistByRegion';
  uRLForgetDDByDist = this.WFMURL + 'getDDByDist';
  uRLForgetCityByDirDist = this.WFMURL + 'getCityByDirDist';
  uRLForgetStoresByCity = this.WFMURL + 'getStoresByCity';
  uRLForgetStoresByDirDist = this.WFMURL + 'getStoresByDirDist';
  uRLForgetStoresByDist = this.WFMURL + 'getStoresByDist';
  uRLForgetStoresByRegion = this.WFMURL + 'getStoresByRegion';

}

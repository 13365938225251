import { Component } from '@angular/core';
import {  Router } from '@angular/router';
import { QHttpHelperService } from './services/q-http-helper.service';
import { OauthDetails } from './interfaces/oauth-details.model';
import { User } from './interfaces/user';
import { AppLanguages } from './core/enums/appLanguages';
import { LocalKeys } from 'src/app/core/enums/localKeys';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'queue-system';
  authorizedCredentials: OauthDetails;
  languageList = [ 
    { code: 'en', label: 'English' },
    { code: 'ru', label: 'Русский' }
  ];
  public OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));

  constructor(private router: Router,
              private httpHelper: QHttpHelperService,
              private translate: TranslateService, cookie: CookieService,) {
                translate.setDefaultLang(AppLanguages.English);
                if (cookie.get(LocalKeys.Language)) {
                  translate.setDefaultLang(cookie.get(LocalKeys.Language));
                } else {
                  cookie.set(LocalKeys.Language, AppLanguages.English);
                }
    this.authorizedCredentials = new OauthDetails();
    if (sessionStorage.getItem('token') !== null && this.OnUser !== null) {
      const currentToken = JSON.parse(sessionStorage.getItem('token'));
      this.authorizedCredentials.accessToken = currentToken.accessToken;
      this.authorizedCredentials.expiresIn = currentToken.expiresIn;
      this.authorizedCredentials.tokenType = currentToken.tokenType;
      this.authorizedCredentials.scope = currentToken.scope;
      this.httpHelper.setAuthHeaders(currentToken.accessToken);
        const currentDate = new Date();
        const expireDate = new Date (new Date().getTime() + (1000 * currentToken.expiresIn));

    } else {
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('RolePrivilege');
      
    }
 }
}


import { Injectable } from '@angular/core';
import { AlertMsg } from '../interfaces/alert-msg';
import { Router, NavigationStart } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertPopService {
  private subject = new Subject<any>();
  private keepAfterRouteChange = false;
    alertMsg: AlertMsg = {
      title: '',
     msg: '',
     inputFlag: false,
     inputValue: '',
     showPage: false,
    };
  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
  this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
          if (this.keepAfterRouteChange) {
              // only keep for a single route change
              this.keepAfterRouteChange = false;
          } else {
              // clear alert message
              this.clear();
          }
      }
  }); }


// showAlert(title , msg) {

//   this.alertMsg.title =  title;
//   this.alertMsg.msg = msg;
//   this.alertMsg.showPage = true;
// }
getAlert(): Observable<any> {
  return this.subject.asObservable();
}

// success(message: string, keepAfterRouteChange = false) {
//   this.keepAfterRouteChange = keepAfterRouteChange;
//   this.subject.next({ type: 'success', text: message });
// }

showMsg(message: string, title: string, keepAfterRouteChange = false) {
  this.keepAfterRouteChange = keepAfterRouteChange;

  this.subject.next({title , text: message });
}


// error(message: string, keepAfterRouteChange = false) {
//   this.keepAfterRouteChange = keepAfterRouteChange;
//   this.subject.next({ type: 'error', text: message });
// }

clear() {
  // clear by calling subject.next() without parameters
  this.subject.next();
}



}

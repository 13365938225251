import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeConst {
  constructor() { }

  TicketOperation = 1;
  WelcomeDashboard = 2;
  Reports = 3;
  DeskPage = 4;
  addDesk = 5;
  editDesk = 6;
  deleteDesk = 7;
  BranchListPage = 8;
  BranchSearch = 9;
  BranchEdit = 10;
  SearchDesk = 11;
  UserPage = 12;
  AddOpsUser = 13;
  AddAgentUser = 14;
  SearchUser = 15;
  EditUser = 16;
  AvgPerBranchReport = 17;
  TopAgentReport = 18;
  RolesPage = 19;
  AddRolesPage = 20;
  RolePrivilegePage = 21;
  PendingPage = 22;
  ServicesPage = 23;
  addService = 24;
  editService = 25;
  SearchService = 26;
  AddManyStoresUser = 27;
  CurrentSessions = 28;
  FlushCurrentSessions = 29;
  BranchAdd = 30;
  enableService = 31;
  DevicesPage = 32;
  AvgPerAgentReport = 33;
  DeleteAssignedUserStore = 34;
  DeleteDevice = 35;
  ModelsManagement = 36;
  UnderServingTickets = 37;
  LaunchDevicesReservations = 38;
}

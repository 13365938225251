import { Component, OnInit, Output , EventEmitter } from '@angular/core';
import { AlertPopService } from './alertpop.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {

  showPage;
  msg = 'Modal title';
  title = 'Hi there.';
  inputFlag: boolean;
  inputValue: string;
  @Output() closePopup = new EventEmitter<any>();
  constructor( private alertpop: AlertPopService) { }
  private subscription: Subscription;
  private showAlertModal = false;
    message: any;
  ngOnInit() {
    // this.title = this.alertpop.alertMsg.title;
    // this.msg = this.alertpop.alertMsg.msg;
    // this.inputFlag = this.alertpop.alertMsg.inputFlag;
    // this.inputValue = this.alertpop.alertMsg.inputValue;
    // this.showPage = false;
    this.subscription = this.alertpop.getAlert()
    .subscribe(message => {
        // switch (message && message.type) {
        //     case 'success':
        //         message.cssClass = 'alert alert-success';
        //         break;
        //     case 'error':
        //         message.cssClass = 'alert alert-danger';
        //         break;
        // }

        this.message = message;
        // this.showAlertModal = true;

        // this.title = message;
    });
}

    // ngOnDestroy() {
    // this.subscription.unsubscribe();
    // }

  close() {
    // this.showAlertModal = false;
    this.alertpop.clear();
    // this.subscription.unsubscribe();
  }
  close2($event) {
    this.closePopup.emit($event);
  }

}
